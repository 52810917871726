import React from 'react';
import { graphql } from 'gatsby';
import Slide from 'react-reveal/Slide';
import Img from 'gatsby-image';
import styled from 'styled-components';

import SEO from '../components/seo';

import { Menu } from '../styles/styles.js';

const CardContainer = styled.main``;

const Card = styled.div`
  margin: 0 2% 30px;
  border-radius: 6px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: 0.3s;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const CardMedia = styled.div`
  img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

const Fotos = ({ data }) => (
  <>
    <SEO title="Fotos" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Fotos</h1>
      </Slide>

      <CardContainer>
        <Card>
          <CardMedia>
            <Img
              fluid={data.uno.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.due.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.tre.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.quattro.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.cinque.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.sei.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.sette.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.otto.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.nove.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.dieci.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.undici.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.dodici.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.tredici.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.quattordici.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.quindici.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
        <Card>
          <CardMedia>
            <Img
              fluid={data.sedici.childImageSharp.fluid}
              objectFit="cover"
              alt=""
            />
          </CardMedia>
        </Card>
      </CardContainer>
    </Menu>
  </>
);

export const query = graphql`
  query {
    uno: file(relativePath: { eq: "batata-recheada.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    due: file(relativePath: { eq: "batata-recheada-delivery.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tre: file(relativePath: { eq: "bolinho.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quattro: file(relativePath: { eq: "filet-de-frango-grelhado.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cinque: file(relativePath: { eq: "filet-de-tilapia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sei: file(relativePath: { eq: "filet-mignon-acebolado.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sette: file(
      relativePath: { eq: "filet-mignon-parmegiana-individual.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    otto: file(relativePath: { eq: "filet-mignon-parmegiana-prato.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nove: file(relativePath: { eq: "marmitex.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dieci: file(relativePath: { eq: "marmitex-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    undici: file(relativePath: { eq: "picanha.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dodici: file(relativePath: { eq: "pizza.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tredici: file(relativePath: { eq: "pizza-pedaco.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quattordici: file(relativePath: { eq: "porcao-de-tilapia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quindici: file(relativePath: { eq: "tilapia-a-parmegiana.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sedici: file(relativePath: { eq: "salada-mista.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Fotos;
